import { render, staticRenderFns } from "./Dialog.vue?vue&type=template&id=57cc6870&scoped=true"
import script from "./Dialog.vue?vue&type=script&lang=ts"
export * from "./Dialog.vue?vue&type=script&lang=ts"
import style0 from "../style.scss?vue&type=style&index=0&id=57cc6870&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57cc6870",
  null
  
)

export default component.exports